import React from "react";
import { Container, Grid } from "@mui/material";

const videos = [
  "D6qJ_cdoV-o",
  "p3NYmHm5rSI", // Added new video
  "DLADbnlzqj0",
  "SOpIBBaUmUw",
  "vtod_Ni5_QA",
  "uC6GHBweZQo",
];

const VideoPlayer: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {videos.map((videoId, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <iframe
              style={{
                width: "100%",
                height: "315px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
              }}
              src={`https://www.youtube.com/embed/${videoId}`}
              title={`YouTube video player - ${videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VideoPlayer;
