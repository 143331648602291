import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, Container, Grid, styled } from "@mui/material";
import one from "../assets/news/one.jpeg";
import two from "../assets/news/two.png";
import three from "../assets/news/three.jpeg";
import pic1 from "./newsimage/2.jpg";
import pic2 from "./newsimage/3.jpeg";
import pic3 from "./newsimage/4.jpg";
import pic4 from "./newsimage/5.jpg";
import pic5 from "./newsimage/6.jpg";
import pic6 from "./newsimage/7.jpg";
import pic7 from "./newsimage/8.jpeg";
import pic8 from "./newsimage/9.jpg";
import comming from "./newsimage/comming.jpeg";
import hardwpic from "./newsimage/hardwpic.jpeg";
import piccow from "./newsimage/piccow.jpg";
import studioabv from "./newsimage/studioabv.jpg";
import Achshav from "./newsimage/Achshav.jpeg";
import New from "./newsimage/new.jpeg";

interface Item {
  img?: string;
  title: string;
  text: string;
  date: string;
  video?: string;
  large?: boolean; // Optional property to indicate if the item is large
  tall?: boolean; // Optional property to indicate if the item is tall
}
interface GridSize {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}
type SortItemsFunction = (a: Item, b: Item) => number;

const itemData = [
  {
    img: New, // השתמשתי בתמונה מ-"newsimage/new.jpeg" אך ניתן לשנות במידת הצורך
    title:"September 3,2024 : A new single has just been released!",
    text: "The song 'Ruach Hatkufa' takes a heartfelt look back at a cherished era that has gone by, yet it carries a powerful sense of hope for the brighter days that are still to come. Lyrics by the legendary Dudu Barak, with composition, production, and performance by Yoav Asif.",
    date: "2024-09-03",
    large: true, // זהו פריט גדול שיתפוס שני עמודות ושתי שורות
    tall: true,
  },
  {
    img: comming,
    title: "Soldier J - New Song Coming Soon!",
    text: "I'm excited to announce that on May 7, 2024, I will release a new song called 'SOLDIER J', which I am very excited about",
    date: "2024-11-30",
    large: true, // This item will span two columns
    tall: true, // This item will span two rows
  },
  {
    img: one,
    title: "Forging My Own Path",
    text: "The lights sparkled, and the audience filled the venue by the thousands. The drummer beside me on stage had been someone I admired since childhood, and the renowned artist at the forefront was instantly recognizable to everyone. For seven years, I shared the stage with him at major stages. Together, we released several albums and performed numerous shows, each time refining our craft for the fans, the nation, and the people. I gained profound insights from him about the cost of fame, self-fulfillment, pursuing your goals, and love. Above all, I learned the significance of following your own path to the very end. After many rewarding years with Yehuda Poliker and Alon Hillel (who remains a dear and cherished friend), I now feel it is time for me to forge my own path.",
    date: "2024-04-17",
    large: true, // This item will span two columns
    tall: true, // This item will span two rows
  },
  {
    img: two,
    title: "An Old Saying Gets Better",
    text: "On November 30, 2024, I had the privilege of performing at the unveiling ceremony of the tombstone honoring Adi Leon, a soldier, fighter, and hero who lost his life in the battles in Gaza. I sang 'Now It's My Turn,' a song I composed using Adi's last words, and shared a few words in his memory. During my speech, I expressed that the familiar phrase 'We have no other country' can no longer stand alone but must include Adi's poignant addition: 'Now it's my turn to protect it.' Adi's profound words have given new depth and meaning to this well-known phrase for me.",
    date: "2024-04-26",
    large: true, // This item will span two columns
    tall: true, // This item will span two rows
  },

  {
    video: "https://www.youtube.com/embed/BGfaNHQM6Hc",
    title: "Israelis And Iranians Make Music Together",
    text: "A few years ago, I somehow managed to show that Israelis and Iranians can make music together. Why can't the same be achieved in politics as it can be through music? The answer is probably complicated. Nevertheless, I invite you to watch 'Touch You,' featuring Elinor Chriqui and Saeed Hosseini. Saeed is a musician and friend from distant Iran.",
    date: "2024-04-27",
    large: true, // This item will span two columns
    tall: true, // This item will span two rows
  },

  {
    title: "Yom Kippur Expires",
    text: "The talented lecturer spoke passionately about the Coltrane scale, which hails from the world of jazz. He took out his golden, vintage tenor saxophone and began to play. Warm, tender, and enchanting sounds filled the room, captivating all the young listeners, myself included. I took two courses with him: jazz theory and an improvisation workshop. One day after the workshop, I approached him and told him I wanted to learn how to play the saxophone. He looked at me and said, 'You will be a great saxophonist!' He helped me arrange the purchase of the perfect saxophone. Full of excitement, I traveled half a day to retrieve it and returned home with a small suitcase holding the golden marvel. Two months later, despite overcoming the challenges of bleeding lips and even beginning to improvise on the instrument, I decided to sell it. Perhaps I didn't feel I could fully connect with the saxophone. With the money from the sale, I bought a bass guitar and fell completely in love with it. You're invited to listen to a piece I improvised one evening right after Yom Kippur, when the night was quiet and full of potential for creative expression.",
    video: "https://www.youtube.com/embed/_QG1T-MkJHQ",
    date: "2024-04-24",
    large: true,
    tall: true,
  },
  {
    img: pic1,
    title: "Eyes Wide Shut",
    text: "In my natural state, I close my eyes when I sing. They say that the eyes are the window to the soul because they express joy, love, success, or sadness, anger, and frustration. So, why do my eyes sometimes get closed when I sing? Perhaps it happens because when a person sings, the soul seeks to express itself through that same range of emotions, but this time everything must come out of the mouth. So how does one connect with the audience with eyes closed? Through the sounds... And gradually, over time, the soul learns to express itself through the mouth, and the eyes align with it, managing to open up while remaining closed wide shut.",

    date: "2024-04-25",
    large: false,
    tall: true,
  },
  {
    img: pic2,
    title: "Time stood still",
    text: "The day, bathed in bright winter sunlight, stretched before me. My friend, a seasoned diver and underwater photographer, casually pulled out his camera and said, 'Alright, get in the water.' Honestly, I felt an internal need to cleanse myself, to wash away the weight of the world. I jumped into the water; time stood still, and so did I.",
    date: "2024-04-23",
    large: false,
    tall: false,
  },
  {
    img: pic3,
    title: "Long Day",
    text: "Sometimes the smile comes from within. At the end of a day in my studio, tired but satisfied.",
    date: "2024-04-22",
    large: false,
    tall: false,
  },
  {
    img: pic4,
    title: "When the Heart Expands",
    text: "I remember that moment exactly. My mother walked into the room holding a gift, the elf doll I had wanted so much. I remember the film camera she held in her hand and the flash that dazzled my eyes. But it wasn't my eyes that were dazzled; it was my heart that swelled with joy. And even today, it continues to grow and expand, especially when I realize how lucky I am to have her, my mom.",
    date: "2024-04-21", // Example date, adjust as needed
    large: true,
    tall: false,
  },
  {
    img: pic6,
    title: "A Fragment of My Past",
    text: "Years ago, I parted with my cherished acoustic piano—a piece of my history that had been with me since infancy, echoing the melodies my older brother played so beautifully. I spent countless hours as a child practicing on it, and as I grew, it became the vessel for my songwriting. When I sold it, I thought little of it at the time. Yet, in hindsight, I see that I let go of a piece of myself. Though my original piano remains elusive, I've rediscovered a fragment of my past in another instrument with its own wood and soul. It's truly special to have it here, finely tuned and ready to play.",
    date: "2024-04-19", // Example date, adjust as needed
    large: true,
    tall: true,
  },
  {
    img: hardwpic,
    title: "Hardware or hard work?",
    text: "There is no denying that the blessings of the digital revolution have greatly contributed to the world of music. Creation has become more accessible than ever, and it is quite amazing that one can record and mix an entire album entirely within a computer. And yet, there are those who appreciate and respect the old-school hardware. Some believe that mixing analog is faster, while others think it complicates and prolongs the process. In my humble opinion, there's no need to choose—one can simply go for a hybrid setup that combines the incredible editing capabilities of the computer with the soft, warm, and delightful sound of the old iron monsters.",
    date: "2024-04-17", // Example date, adjust as needed
    large: true,
    tall: true,
  },
  {
    img: pic5,
    title: "My Journey",
    text: "What is the journey from black and white to a world of color? Through the realm of melody and song.",
    date: "2024-04-20", // Example date, adjust as needed
    large: true,
    tall: false,
  },
  {
    img: pic7,
    title: "Worth it!",
    text: "Although it's often said that musicians prefer to sleep in, the breathtaking view outside makes the early rise more than worth it. By the way, my mobile phone must have accidentally taken this stunning photo while I was walking. I later just straightened it and placed it here :)",
    date: "2024-04-18", // Example date, adjust as needed
    large: false,
    tall: false,
  },
  {
    img: piccow,
    title: "Unexpected Paths to Beauty",
    text: "I was feeling stuck in my studio when, unexpectedly, a woman knocked on my door, eager to sing despite having no professional experience. We chatted and laughed, forming a connection. It turned out she was also a tour guide. Fast forward two weeks, and I found myself in a stunning location just a 12-minute drive from my studio. In a moment of perfect timing, I snapped a photo of an adorable cow with my phone. Sometimes, the greatest treasures are right under our noses—we just need to break free from our routines to discover them.",
    date: "2024-04-16", // Example date, adjust as needed
    large: false,
    tall: false,
  },
  {
    img: studioabv,
    title: "Haulpaniya",
    text: "The studio above is where my small wonders come to life—where my music awakens, fresh harmonies emerge, and songs bloom. It's a space I lovingly crafted to make room for my dreams and the dreams of others.",
    date: "2024-04-15", // Example date, adjust as needed
    large: false,
    tall: false,
  },
  {
    img: Achshav,
    title: "Now It's My Turn",
    text: "Adi Leon, a heroic Israeli-Jewish warrior who fell in battle in Gaza, left behind a poignant letter with final words that inspire: I go into this war knowing I may not return, but I believe with all my heart in what I am doing. I have no other land, and now it's my turn to defend it. I transformed Adi's last words into a song and I'm proud to play a small part in honoring the memory of this brave soldier. I am thrilled to announce the official release of the song ACHSHAV TORI (Now It's My Turn).",
    date: "2024-04-14", // Example date, adjust as needed
    large: false,
    tall: false,
  },
];
const PartiallyHiddenCard = styled(Card)<{ showFullContent: boolean }>(
  ({ showFullContent, theme }) => ({
    overflow: "hidden", // Hide overflow content
    position: "relative",
    maxHeight: showFullContent ? "none" : "250px", // Limit height initially
    transition:
      "max-height 0.3s ease-in-out, transform 0.3s ease, box-shadow 0.3s ease", // Include transitions for height, transform and shadow
    cursor: "pointer", // Change cursor to pointer to indicate it's clickable
    "&:hover": {
      boxShadow: theme.shadows[4], // Apply a deeper shadow on hover
      transform: "scale(1.01)", // Slightly scale up the card on hover
    },
  })
);
const MyCard = styled(Card)(({ theme }) => ({
  margin: 0, // Ensures no margin around the card
  width: "100%", // Full width within its container
  height: "fit-content", // Height adjusts to content
  boxShadow: theme.shadows[1], // Consistent shadow
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    boxShadow: theme.shadows[4],
    transform: "scale(1.01)",
  },
  display: "inline-block", // Inline behavior for alignment
}));

const MyCardContent = styled(CardContent)({
  padding: 16, // Ensures there's padding around the text
  "&:last-child": {
    paddingBottom: 16, // Consistent padding
  },
});

const NewsDate = styled(Typography)({
  color: "grey",
  fontSize: "0.8rem",
  marginTop: "0.5rem",
  marginBottom: "1rem", // adjust spacing as needed
});

const MyCardMedia = styled(CardMedia)({
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // Maintain a 16:9 aspect ratio
  "&:after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    transition: "background-color 0.3s ease", // Smooth transition for background color
  },
  "&:hover:after": {
    backgroundColor: "transparent", // Remove background color on hover
  },
});

const NewsTitle = styled(Typography)({
  fontWeight: "bold",
});

const NewsText = styled(Typography)({
  color: "gray",
});
interface NewProps {
  newsRef: React.RefObject<HTMLDivElement>;
}

const News: React.FC<NewProps> = ({ newsRef }) => {
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [showFullFourth, setShowFullFourth] = React.useState(false);
  const [newsPosition, setNewsPosition] = React.useState<number>(0);
  const moreNewsRef = React.useRef<HTMLButtonElement>(null);

  const handleShowMore = (): void => {
    setShowAll(true);
    setShowFullFourth(true); // Also reveal the full text of the fourth item
  };

  const handleCancel = (): void => {
    setShowAll(false);
    setShowFullFourth(false); // Reset the view of the fourth item

    // Delay the scroll slightly to ensure it occurs after state updates and DOM rendering
    setTimeout(() => {
      if (moreNewsRef.current) {
        // Directly using window.scrollTo for more control if needed
        const offsetTop = moreNewsRef.current.offsetTop;
        window.scrollTo({
          top: offsetTop - 500, // Adjust this value based on your fixed headers or other elements
          behavior: "smooth",
        });
      }
    }, 100); // Adjust delay time if needed
  };

  const visibleItems = showAll ? itemData : itemData.slice(0, 4);

  return (
    <Container>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-evenly" }}
      >
        {visibleItems.map((item, index) => (
          <Grid
            item
            xs={index === 0 ? 12 : item.tall ? 12 : 12}
            sm={index === 0 ? 12 : item.large ? 12 : 12}
            md={index === 0 ? 12 : item.large ? 6 : 12}
            lg={index === 0 ? 12 : item.video ? 6 : 5}
            xl={index === 0 ? 12 : item.video ? 6 : 6}
            key={index}
          >
            <PartiallyHiddenCard
              showFullContent={index !== 3 || showFullFourth}
            >
              {item.video ? (
                <CardMedia
                  component="iframe"
                  src={item.video}
                  title={item.title}
                  style={{ height: "500px", width: "100%" }}
                />
              ) : (
                <CardMedia
                  component="img"
                  image={item.img}
                  title={item.title}
                />
              )}
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    marginBottom: 1.3,
                    fontSize: {
                      xs: "1.2rem", // larger font size on extra-small screens
                      sm: "0.975rem", // default body1 size for small screens and up
                    },
                  }}
                >
                  {item.text}
                </Typography>
                <Typography variant="body2" color="grey">
                  {/* {new Date(item.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })} */}
                </Typography>
              </CardContent>
            </PartiallyHiddenCard>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
          paddingBottom: "20px",
        }}
      >
        {!showAll ? (
          <Button
            onClick={handleShowMore}
            ref={moreNewsRef}
            variant="contained"
            style={{ visibility: showAll ? "hidden" : "visible" }}
          >
            More News
          </Button>
        ) : (
          <Button onClick={handleCancel} variant="contained">
            Less News
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default News;
