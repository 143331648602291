import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
const AppContainer = styled.div`
  display: grid;
  margin-bottom: 3rem;
  place-items: center;
`;
const ContentContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  background: #d4c2af;
  border-radius: 10px;
  margin-button: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledHeading = styled(Typography)`
  && {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 2rem;
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
  }
`;

const StyledParagraph = styled(Typography)`
&& {
  font-family: "Castoro", serif;
  margin-bottom: 16px;
  color: #34495e;
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify;
`;

const StyledHighlight = styled.span`
  color: #d35400;
  font-weight: bold;
`;

const Biography = () => {
  return (
    <AppContainer>
      <ContentContainer>
        <StyledParagraph>
          <StyledHighlight
            style={{
              textTransform: "uppercase",
            }}
          >
            Yoav Asif
          </StyledHighlight>{" "}
          is a singer-songwriter who creates, plays, and mixes his own songs
          from within his intimate studio. His songs delve into the themes of
          peace and war, love and childhood, as well as life's struggles and
          moments of joy. At a young age, he began studying classical piano at a conservatory,
          but it was during his teenage years that he started playing the
          guitar, bass, and drums. Asif grew up in Haifa, where he studied law at a
          university and worked as a lawyer before deciding to move to Tel Aviv
          (the big city) to pursue his passion for music.
        </StyledParagraph>
        <StyledParagraph>
          After volunteering as a musician for a local
          theater, he was invited to play keyboards with the renowned{" "}
          <StyledHighlight style={{ fontWeight: "unset" }}>
            Yehuda Poliker
          </StyledHighlight>{" "}
          for seven years, performing on stages such as the{" "}
          <StyledHighlight style={{ fontWeight: "unset" }}>
            Amphipark Cesarea
          </StyledHighlight>
          , Sultan's Pool, Hangar 11, the{" "}
          <StyledHighlight style={{ fontWeight: "unset" }}>
            Heical Hatarbut
          </StyledHighlight>
          , and Zappa. He collaborated with notable producers like Louis Lahav
          (known for his work with{" "}
          <StyledHighlight style={{ fontWeight: "unset" }}>
            Bruce Springsteen
          </StyledHighlight>
          ) and Yaakov Gilad (as part of the audio-tech company Waves Audio).
        </StyledParagraph>
        <StyledParagraph>
          Almost immediately after transitioning towards music, he established
          his studio, <StyledHighlight>"HAULPANIYA"</StyledHighlight> where he composes, arranges, produces, and
          mixes music for artists and commercial brands. After spending several years engaged in these activities and continuing to maintain them, Yoav decided it was time to step onto the forefront of the stage.
        </StyledParagraph>
        <StyledParagraph>
          A few months ago, he released his debut single, "Now
          It's My Turn," in memory of the fallen Israeli soldier Adi Leon, which
          received widespread airplay on local radio stations and national
          television.
        </StyledParagraph>
        <StyledParagraph>
          Now, he is releasing <StyledHighlight>"SOLDIER J"</StyledHighlight>, a
          song where he calls on all soldiers to hold onto hope for better days
          ahead.
        </StyledParagraph>
      </ContentContainer>
    </AppContainer>
  );
};

export default Biography;
