import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  Container,
  Grid,
  Link,
  InputLabel,
} from "@mui/material";
import styled from "styled-components";
import emailjs, { EmailJSResponseStatus } from "emailjs-com";
interface ContactFormValues {
  interest: string;
  email: string;
  message: string;
}
const MainConnected = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
`;

const StyledContainer = styled(Container)`
  border-radius: 0.5em;

  width: 80% !important;
  padding: 4em;

  @media (max-width: 768px) {
    width: 90% !important;
  }
  @media (max-width: 480px) {
    width: 95% !important;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    background: #f5f5f5;
    border-radius: 0.4em; // Slightly rounded corners
    border: none; // No visible border

    fieldset {
      border-color: transparent !important; // Ensure border is always transparent
    }

    input {
      color: black !important;
    }

    label {
      color: black !important;
    }
    fieldset {
      border-color: transparent; // Removes the border
    }
    &:hover fieldset {
      border-color: transparent; // Keeps the border transparent on hover
    }
    &.Mui-focused fieldset {
      border-color: transparent; // Keeps the border transparent when focused
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #3c5099;
    color: #fff;
    margin-top: 1em;
    font-weight: bold;
    letter-spacing: 0.05rem;
    text-transform: none; // In case you want to keep the casing as is
    padding: 0.75em 1.5em;
    border: none;
    border-radius: 0.4em; // Slightly rounded corners
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Subtle shadow
    transition: all 0.3s ease; // Smooth transition for hover effect
  
  &:hover {
    background-color: #3c5099;
    color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); // Slightly deeper shadow on hover
    transform: translateY(-2px); // Slight lift
  }

  &:active {
    transform: translateY(1px); // Pushed effect when clicked
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); // Shallower shadow to simulate being pressed
  }

  &:disabled {
    background-color: #2c2f33;
    color: #4f5256;
    cursor: not-allowed;
    box-shadow: none;
  }

  // If you are using a <Link> component from react-router-dom or similar inside the button:
  .MuiButton-label > a {
    color: inherit; // This ensures the Link inherits the color from the button
    text-decoration: none; // No underline
  }
`;

const StyledTypography = styled(Typography)`
  color: #fff;
  font-size: 1.6em;
  line-height: 1.5;
  margin: 0.5em 0;
`;
const StyledSelectField = styled(TextField)`
  fieldset {
    border-color: transparent !important; // Ensure border is always transparent
  }

  ,
  input {
    color: black !important;
  }

  label {
    color: black !important;
  }
  fieldset {
    border-color: transparent; // Removes the border
  }
  &:hover fieldset {
    border-color: transparent; // Keeps the border transparent on hover
  }
  &.Mui-focused fieldset {
    border-color: transparent; // Keeps the border transparent when focused
  }
  & + & {
    // This ensures that the style is applied when one StyledTextField is followed by another
  }
  label {
    color: #8e8e8e !important;
  }
  .MuiInputBase-root {
    color: black; // Sets the text color
    background-color: #f5f5f5; // Sets the background color
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #555; // Sets the border color
  }

  .MuiInputLabel-root {
    color: ${(props) => (props.value ? "transparent" : "black")} !important;
  }

  .MuiSelect-icon {
    color: #fff; // Sets the dropdown icon color
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #bbb; // Sets the border color on hover
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff; // Sets the border color when the select is focused
  }

  &.Mui-focused .MuiInputLabel-root {
    color: #fff; // Sets the label color when the select is focused
  }

  background: #333; // Sets the background color
  border-radius: 4px; // Sets the border radius

  // This part is for the select menu items
  .MuiMenu-paper {
    background-color: #333; // Sets the background color of the menu
  }

  .MuiMenuItem-root {
    color: #fff; // Sets the text color of menu items

    &:hover {
      background-color: #444; // Sets the background color on hover
    }

    &.Mui-selected {
      background-color: #555; // Sets the background color of the selected item
    }
  }
`;
interface ContactFormValues {
  interest: string;
  email: string;

  message: string;
}
const Contact = () => {
  const [formValues, setFormValues] = useState<ContactFormValues>({
    interest: "",
    email: "",
    message: "",
  });
  const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    debugger;
    if (!isValidEmail(formValues.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    try {
      const formData = {
        ...formValues,
        // Ensure all values are converted to strings
        interest: String(formValues.interest),
        email: String(formValues.email),
        message: String(formValues.message),
      };

      const response: EmailJSResponseStatus = await emailjs.send(
        "service_b1ekjff",
        "template_9wazd9x",
        formData,
        "0HmsnpXTdefnudwLi"
      );
      console.log("Email sent successfully:", response);
      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred while sending the email.");
    }
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <StyledContainer maxWidth="sm" sx={{ marginTop: "-6rem" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <StyledSelectField
              select
              sx={{
                marginTop: "1em",
                marginBottom: "1em",
              }}
              label="I want" // Label to act as placeholder visually if needed
              fullWidth
              name="interest"
              value={formValues.interest || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: false }} // This will prevent the label from moving upwards
            >
              <MenuItem value="interview">An interview</MenuItem>
              <MenuItem value="music_concert">A music concert</MenuItem>
              <MenuItem value="composed_soundtrack">
                An original composed soundtrack
              </MenuItem>
              <MenuItem value="music_production">
                Music production / an arrangement / mixing
              </MenuItem>
              <MenuItem value="cooperate_musically">
                To cooperate musically
              </MenuItem>
              <MenuItem value="other">other</MenuItem>
            </StyledSelectField>

            <StyledTextField
              fullWidth
              focused
              name="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />

            <StyledTextField
              fullWidth
              name="message"
              placeholder="Write your message here"
              multiline
              rows={4}
              value={formValues.message}
              onChange={handleChange}
              sx={{
                background: "#333",
                borderColor: "white",
                color: "white !important",
                marginTop: "1em",
              }}
            />
            <StyledButton type="submit" fullWidth>
              Send
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </StyledContainer>
  );
};

export default Contact;
