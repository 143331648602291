import React, { useState, useRef } from "react";
import {
  Box,
  Container,
  IconButton,
  Slider,
  Stack,
  Typography,
  useTheme,
  Modal,
} from "@mui/material";

import { useSpring, animated } from "react-spring";
import CloseIcon from "@mui/icons-material/Close";

import styled from "@mui/material/styles/styled";
import Escalator from "../../assets/songs/Escalator.mp3";
import EscalatorPic from "../../assets/songs/Escalator.jpg";
import FieldsofIris from "../../assets/songs/FieldsofIris.mp3";
import FieldsofIrisPic from "../../assets/songs/FieldsofIris.jpg";
import FromHeretoThere from "../../assets/songs/FromHeretoThere.mp3";
import MySoldier from "../../assets/songs/MySoldier.mp3";
import MySoldierPic from "../../assets/songs/MySoldier.jpeg";
import FromHeretoTherePic from "../../assets/songs/FromHeretoThere.jpg";
import Notafraid from "../../assets/songs/NotAfraid.mp3";
import NotafraidPic from "../../assets/songs/NotAfraid.jpg";
import NowItsMyTurn from "../../assets/songs/NowItsMyTurn.mp3";
import NowItsMyTurnPic from "../../assets/songs/NowItsMyTurn.jpg";
import TouchYou from "../../assets/songs/TouchYou.mp3";
import TouchYouPic from "../../assets/songs/TouchYou.jpg";
import RUACHHATKUFAPIC from "../../assets/songs/RUACHHATKUFA.png";
import RUACHHATKUFA from "../../assets/songs/RUACHHATKUFA.mp3";
import { Grid, Paper } from "@mui/material";
interface Song {
  src: string;
  img: string;
  title: string;
  lyrics?: string; // Optional lyrics field
  details?: string; // Optional details field
}
const songs: Song[] = [
  {
    src: RUACHHATKUFA,
    img: RUACHHATKUFAPIC,
    title: "Ruach Hatkufa",
    lyrics: `נעלמה, התעופפה לה / רוח התקופה
בושם חורש אפופה לה / רוח התקופה
לא תשוב היא עוד אלינו / נעלמה מעולמנו / רוח התקופה

כסופת ילדות חלפה לה / רוח התקופה
כזנב סוס היא ריחפה לה / רוח התקופה
סחפה את ילדותנו / את תפרחת נעורינו / רוח התקופה

מראה עיניים / משמע אוזניים / מגע ידיים / רוח התקופה
דבש על שפתיים / צוף בנחיריים / בלב – / היין

כל ילדה הייתה יפה / ברוח התקופה
איך חיכתה לאלופה / ברוח התקופה
השירים בלשון השגב / שרו על גליל ונגב / רוח התקופה

געגוע חי בלב / עם רוח התקופה
תעתוע מלבלב / עם רוח התקופה
זיכרונות בנפש פנימה / מבית אבא, מבית אמא / רוח התקופה`,
    details: "לחן וביצוע: יואב אסיף, מילים: דודו ברק",
  },
  {
    src: MySoldier,
    img: MySoldierPic,
    title: "My Soldier",
    lyrics: `My soldier / Are you back on the ground
With your feet still attached / To your unbroken heart?
My soldier / They have sent you to fight
But you know they are right / Can you feel it inside?

All the innocents taken under the gun
With strong beliefs within you'll find everyone
Though you fear this war, you know it's a must
Your people won't be turned to ashes and dust

My soldier / There are things to be done
And some dreams to be won / True love is waiting around
And your dad / Will be more than proud
Mom thinks you bring the light / With your American brothers-in-arms

Be'eri was doomed to massacre and fire
The Nova festival has ceased to inspire
And all the children that were kidnapped to hell
In those demonstrations, can they hear them pray?

My soldier / Heros can't really die
It's ok that you cried / Keep the faith in your god
Some better days, you hope / Are yet to come
Bombs will turn into drums / And the music will hum

All the souls ascending to heavens above
Will be gathered just to send you some love
If your enemies will push us to spread
You'll just keep showing them, you're not afraid

My soldier / My warrior
My child, keep it simple but true / Live your way as you do
Never let yourself apologize / for being a Jew`,
    details: "Lyrics and music by Yoav Asif",
  },
  {
    src: NowItsMyTurn,
    img: NowItsMyTurnPic,
    title: "Now It's My Turn",
    lyrics: `This is the education my parents gave me / In this I believe
It's upon me / All the babies, elders and also women
Will no longer be helpless / We have no other land
Now it's my turn to defend it / Against Hamas and chaos
A loving soldier of Israel will avenge
I go to this war knowing I might not return
But I believe, wholeheartedly in what I'm doing / Now it's my turn

To dad and mom too / To the best friends in the world
I have a little of each, even when I'm there / We have no other land
Tell the child I'm asked about / Against Hamas and chaos
A loving soldier of Israel will avenge
I go to this war knowing I might not return
But I believe, wholeheartedly in what I'm doing / Now it's my turn`,
    details:
      "Performed by: Yoav Asif, Featuring: Nofar Margaliot / Lyrics: Adi Leon and Yoav Asif, Music & Production: Yoav Asif",
  },
  {
    src: Escalator,
    img: EscalatorPic,
    title: "Escalator",
    lyrics: `It can't last / Turning without rest
It's not easy anymore to smile / It can last
Some leave / But where shall I go
What value is there if we lost our way
Everyone wanders in dreams about dreams
And there are those who say everything is ok
Yet, every day we fight to survive

It can't last / You shout yourself outside
But it's not working / The children in the backyard
Playing in promises of what they'll never be
What value is there if we lost our way
What value is there if we didn't truly listen
The king's clothes are made of gold and silver
On the screen, the presenter becomes a judge

It can't last, no / Embrace me and let's close ourselves in our room
The clock shows it's time to go and climb the escalator
My love, come to me`,
    details:
      "Music, Lyrics & Production: by Yoav Asif / Performed by: Yoav Asif, Featuring: Elinor Chriqui & Saeed Hosseini",
  },
  {
    src: FieldsofIris,
    img: FieldsofIrisPic,
    title: "Fields of Iris",
    lyrics: `There were scattered houses there / Islands, islands
My childhood remained there / But what about me
I am a pistol loaded with longings
And it returns to me, you understand?
It returns to me in the room
Every song is an unavoidable memory

In the stadium which is too narrow to contain / I'm stunned
With all the people I sit / Waiting for the concert to start
The band is already ready / A finger is on the trigger
Every song is an unavoidable memory

So I didn't have a big car
And I didn't know what is hiding beyond the single street
Secrets - Fields of Iris

There were similar houses / At night no one could noticed
And sometime, from there, they come to me
Children, or maybe not children
It's not the same country anymore / Not the same room anymore
Every song is an unavoidable memory

Now on a highway / I drive the car you bought
And / I'm careful from people, from dreams & from a racist wind
It's not the same country anymore / Not the same room anymore
Just a song, like an unavoidable memory`,
    details:
      "Music & Lyrics: by Shlomo Artzi / Performance and Production: Yoav Asif, Featuring: Narkis Aharon-Azrad",
  },
  {
    src: FromHeretoThere,
    img: FromHeretoTherePic,
    title: "From Here to There",
    lyrics: `From here to there / Without any warning
Everything seems most addicted
But still, the sun is rising outside
And we're searching for the button / That will fill us with light
Praising the sun / Waiting in the cold

What is more important than what
The descents, the glory
That comes for a moment and in a moment disappears
The questions or the answers / The right mistakes
And how faith resides within

On a highway or in the living room
Who will be first?
And who will get to sleep before time
We don't lie to others / And feel not the best with it

And what's more important than what
The descents, the glory
That comes for a moment and in a moment disappears
The questions or the answers / The right mistakes
And how faith resides within`,
    details:
      "Music, Lyrics & Production: by Yoav Asif / Performed by: Yoav Asif, Featuring: Elinor Chriqui",
  },
  {
    src: Notafraid,
    img: NotafraidPic,
    title: "Not Afraid",
    lyrics: `I'm Not afraid / And not taking any distance
But also not approaching in the Middle East
I'm Not compromising / Not indulging
Not praying to flags or nations

And the worlds, yours and mine - Can be
How much I desire / With a new heart
To embrace without asking questions
About who I was / And who you were
And not to talk about borders again
True forgiveness can still come - Peace

Between the sunsets / Days and nights
Aligned in speech and desires
248 Limbs, 365 Tendons / How different can you be?
And hope, like with me, must be with you as well
Peace

Yitzhak Rabin, may he rest in peace: (His last speech, November 4, 1995, Kings of Israel Square)
"I always believed that the majority of the people:
Want peace, Are willing to take risks for peace,
And you here, by attending this rally, prove that the people truly want peace"`,
    details:
      "Music, Lyrics & Production: by Yoav Asif / Performed by: Menny Begger & Chaya Samir",
  },
  {
    src: TouchYou,
    img: TouchYouPic,
    title: "Touch You",
    lyrics: `You had it all but now it's gone
The voices slowly taking on you
Tomorrow seems so far away
But here I'm asking, let me stay And

TOUCH YOU / I want to touch you
Just want to touch you / Nothing but touching you
Words alone won't be enough
Take my hand and open your heart

You might have known this day would come
When all your fears will join as one
A thousand reasons, why fight back
I'll be here for you to take
As long as you need me to

TOUCH YOU / I want to touch you
Just want to touch you / Nothing but touching you
Words alone won't be enough
Take my hand and open your heart

And nothing will compare / In times of great despair

TOUCH YOU / I want to touch you
Words alone won't be enough
Words alone / words alone / Nothing but touch`,
    details:
      "Music, Lyrics & Production: by Yoav Asif / Performed by: Yoav Asif, Featuring: Elinor Chriqui & Saeed Hosseini",
  },
];

export default function MusicPlayerSlider() {
  const [currentSong, setCurrentSong] = useState<string | null>(null);
  const [activeTitle, setActiveTitle] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const playSong = async (song: Song): Promise<void> => {
    try {
      setIsLoading(true);
      setError(null);
      if (currentSong === song.src && !audioRef.current.paused) {
        audioRef.current.pause();
        setCurrentSong(null);
        setActiveTitle(null);
      } else {
        if (currentSong !== song.src) {
          audioRef.current.src = song.src;
        }
        await audioRef.current.play();
        setCurrentSong(song.src);
        setActiveTitle(song.title);
      }
      setIsLoading(false);
    } catch (err) {
      console.error("Error playing song:", err);
      setError("Failed to play song.");
      setIsLoading(false);
      setCurrentSong(null);
      setActiveTitle(null);
    }
  };

  const stopSong = () => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      setCurrentSong(null);
      setActiveTitle(null);
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ width: "100%", overflow: "hidden", paddingBottom: "2rem" }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {songs.map((song) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={song.title}>
              <Paper
                elevation={3}
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  overflow: "hidden",
                  paddingBottom: "100%",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.03)",
                    boxShadow: 6,
                  },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={song.img}
                    alt={song.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onClick={() => playSong(song)}
                  />
                </Box>
                {activeTitle === song.title && (
                  <Box
                    onClick={stopSong}
                    sx={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: "rgba(0,0,0,0.7)",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      fontSize: "1rem",
                      overflow: "auto",
                      padding: "1rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ mb: 2, alignSelf: "center" }}
                    >
                      {song.title === "Ruach Hatkufa"
                        ? "רוח התקופה"
                        : song.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign:
                          song.title === "Ruach Hatkufa" ? "right" : "left",
                        direction:
                          song.title === "Ruach Hatkufa" ? "rtl" : "ltr",
                        alignSelf: "stretch",
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      {song.details}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: "pre-wrap",
                        textAlign:
                          song.title === "Ruach Hatkufa" ? "right" : "left",
                        direction:
                          song.title === "Ruach Hatkufa" ? "rtl" : "ltr",
                        mb: 2,
                      }}
                    >
                      {song.lyrics}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
