// Navigation.js
import React, { useEffect, useState, RefObject } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import pic2 from "./MyStudio1.jpeg";
import CloseIcon from "@mui/icons-material/Close";
import dots from "../assets/dots.svg";
import dotsblack from "../assets/dotblack.svg";
import about from "../assets/nav/about.jpeg";
import CONTACT from "../assets/nav/CONTACT.jpeg";
import news from "../assets/nav/NEWS.jpeg";
import home from "../assets/nav/HOME.jpeg";
import video from "../assets/nav/VIDEOS.jpeg";
import SONGS from "../assets/nav/SONGS.jpeg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Icon } from "@iconify/react";
import VIDEOS from "./VIDEOS.jpeg";
import ABOUT from "./ABOUT.jpeg";
import HOME from "./HOME.jpeg";

interface StyledListItemProps {
  hoverColor: string;
}

interface StyledDrawerProps {
  backgroundColor: string;
}
interface NavigationProps {
  contactRef: RefObject<HTMLDivElement>;
  musicRef: RefObject<HTMLDivElement>;
  aboutMeRef: RefObject<HTMLDivElement>;
  videoRef: RefObject<HTMLDivElement>;
  studioRef: RefObject<HTMLDivElement>;
  newsRef: RefObject<HTMLDivElement>;
  homeRef: RefObject<HTMLDivElement>;
}
const StyledDrawer = styled(Drawer)<StyledDrawerProps>`
  .MuiDrawer-paper {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    background: ${(props) =>
      props.backgroundColor}; // Use the backgroundColor prop
    transition: background-color 0.5s ease; // Smooth background transition
  }
`;

const NavigationContainer = styled.div`
  .menuIcon {
    color: #fff; // Customize your icon color
  }
`;
const StyledListItem = styled(ListItem)<StyledListItemProps>`
&& {
  margin-top: -2.6rem;
margin-left: 1rem;
cursor: 'pointer',

'&:hover': {
  backgroundColor: hoverColor; // Changes background color on hover
  transition: 'background-color 0.5s ease', // Smooth transition for background color
}

`;
const RotatingImage = styled.img`
  width: 30px;
  height: 50px;
  transition: transform 0.8s ease-in-out; // Smooth transition for the rotation
  cursor: pointer; // Ensures the cursor changes to a pointer when hovering over the image

  &:hover {
    transform: rotate(360deg) scale(1.1); // Rotate the image 360 degrees and enlarge by 10%
  }
`;

const DrawerImage = styled.img`
  height: 100vh;
  object-fit: cover;
  width: 100%;
  align-self: stretch;
  transition: opacity 1.2s ease-in-out; // Smooth opacity transition for the fade effect
  opacity: 0; // Start with the image invisible

  &.visible {
    opacity: 1; // When class 'visible' is added, make the image fully visible
  }
`;

export default function Navigation({
  contactRef,
  musicRef,
  aboutMeRef,
  videoRef,
  studioRef,
  newsRef,
  homeRef,
}: NavigationProps) {
  const [open, setOpen] = React.useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [background, setBackground] = useState("black");
  const [image, setImage] = useState();
  const [activeImage, setActiveImage] = useState(home);
  const [imageLoaded, setImageLoaded] = useState(false); // Track image load state
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const topPos = ref.current.offsetTop;
      const offsetPosition = topPos - 250; // Adjust the offset as needed for visibility

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setOpen(false); // Optionally, close the drawer if it's open
    }
  };

  const handleScroll = () => {
    const hideTitleScrollHeight = 200;

    if (window.scrollY > hideTitleScrollHeight) {
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }
  };
  const handleImageLoaded = () => {
    setImageLoaded(true); // Set loaded state to true when image is loaded
  };
  const handleMenuItemEnter = (item: any) => {
    setImageLoaded(false); // Ensure this is reset before changing the image
    setBackground(item.color);
    setActiveImage(item.image);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const menuItems = [
    { title: "Home", color: "#d9bc9a", image: HOME, ref: homeRef },
    { title: "About", color: "#63cac6", image: ABOUT, ref: aboutMeRef },
    { title: "Songs", color: "#6dbc90", image: SONGS, ref: musicRef },
    { title: "News", color: "#e76152", image: news, ref: newsRef },
    { title: "Videos", color: "#3c5099", image: VIDEOS, ref: videoRef },
    { title: "HAULPANIYA", color: "#ebb158", image: pic2, ref: studioRef },
    { title: "Contact", color: "#95887a", image: CONTACT, ref: contactRef },
  ];
  const handleScrollToHome = () => {
    homeRef.current?.scrollIntoView({ behavior: "smooth" });

    if (homeRef.current) {
      const topPos = homeRef.current.offsetTop;
      const offsetPosition = topPos - 500; // Adjust the offset as needed for visibility

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setOpen(false); // Optionally, close the drawer if it's open
    }
  };
  return (
    <AppBar
      sx={{
        backgroundColor: showTitle ? "white" : "transparent",
        height: "80px",
        transition: "background-color 0.8s ease",
        boxShadow: "none !important",
      }}
    >
      <NavigationContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
              lg={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                edge="start"
                className="menuIcon"
                onClick={toggleDrawer}
                size="large"
                sx={{
                  paddingLeft: "2rem",
                  zIndex: 1,
                  cursor: "pointer",
                  marginTop: isSmallScreen ? "-0.7rem" : "1rem",
                }}
              >
                <RotatingImage
                  src={showTitle ? dotsblack : dots}
                  alt="dots"
                  style={{
                    width: "30px",
                    height: "50px",
                    color: showTitle ? "black" : "white",
                  }}
                />
                {!isSmallScreen && (
                  <p
                    style={{
                      color: showTitle ? "black" : "white",
                      fontSize: "1.4rem",
                      marginBottom: "-0.4rem",
                      fontFamily: "Castoro, serif",
                    }}
                  >
                    Menu
                  </p>
                )}
              </IconButton>
            </Grid>
            <Grid
              item
              xs={7}
              sm={9}
              md={10}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {showTitle && (
                <Typography
                  component="div"
                  onClick={handleScrollToHome}
                  sx={{
                    color: "black",
                    fontFamily: "Castoro, serif",
                    fontSize: isSmallScreen ? "1rem" : "2rem",
                    letterSpacing: "0.5rem",
                    paddingTop: "1rem !important",
                    cursor: "pointer", // Ensure it's clear this element is clickable

                    paddingBottom: "1rem !important",
                  }}
                >
                  YOAV ASIF
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <StyledDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer}
          backgroundColor={background}
        >
          <IconButton
            onClick={toggleDrawer}
            sx={{ color: "white", m: 1, zIndex: 1, position: "absolute" }}
          >
            <CloseIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "space-around",
                  height: "100vh",
                }}
              >
                <Grid item xs={12}>
                  <List>
                    {menuItems.map((item, index) => (
                      <StyledListItem
                        key={item.title}
                        hoverColor={item.color}
                        onClick={() =>
                          item?.ref && handleScrollToRef(item?.ref)
                        }
                        onMouseEnter={() => {
                          handleMenuItemEnter(item.image);
                          setBackground(item.color);
                          setActiveImage(item.image);
                        }}
                        onMouseLeave={() => setBackground("#4d4a4f")}
                      >
                        <Typography
                          sx={{
                            fontSize: isSmallScreen ? "3.1rem" : "4rem",

                            fontFamily: "Castoro, serif",
                            color: "white",
                            textTransform: "uppercase",
                            cursor: "pointer",
                            paddingBottom: "-1rem",
                            paddingTop: "-1rem",
                            transition: "font-size 0.3s ease", // Smooth transition for font size

                            "&:hover": {
                              fontSize: "85px", // Increase the font size on hover
                            },
                          }}
                        >
                          {item.title}
                        </Typography>
                      </StyledListItem>
                    ))}
                  </List>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly", // Distributes space evenly around items
                    alignItems: "center", // Vertically centers items
                    marginLeft: "2rem",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://www.facebook.com/yoav.asif.music"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon sx={{ fontSize: 40, color: "white" }} />
                  </a>
                  <a
                    href="https://www.instagram.com/yoav_asif/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon sx={{ fontSize: 40, color: "white" }} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCHOjTZw4DO2L6Hewo5nsiYg/videos/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YouTubeIcon sx={{ fontSize: 40, color: "white" }} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@yoav.asif"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      icon="mage:tiktok"
                      color="white"
                      style={{ fontSize: "40px", cursor: "pointer" }}
                    />
                  </a>
                  <a
                    href="https://open.spotify.com/artist/4B2TFeQi8WQFylDVqhRGSu?si=zvcve933SNSOhfs34Ry5Bw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {/* Update with actual Spotify link if available */}
                    <Icon
                      icon="akar-icons:spotify-fill"
                      color="white"
                      style={{ fontSize: "40px", cursor: "pointer" }}
                    />
                  </a>
                  <a
                    href="https://music.apple.com/il/artist/yoav-asseraf-asif/1723069784"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {/* Update with actual Spotify link if available */}
                    <Icon
                      icon="uil:apple"
                      color="white"
                      style={{ fontSize: "40px", cursor: "pointer" }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
            {window.innerWidth >= 600 && ( // Adjust the threshold according to your preferred breakpoint
              <Grid item xs={12} sm={6} md={6}>
                <DrawerImage
                  src={activeImage}
                  onLoad={handleImageLoaded}
                  className={imageLoaded ? "visible" : ""}
                  alt="Side Image"
                />
              </Grid>
            )}
          </Grid>
        </StyledDrawer>
      </NavigationContainer>
    </AppBar>
  );
}
