
import React from "react";
import styled from "styled-components";

// Footer container styling
const FooterContainer = styled.footer`
  width: 100%;
  padding: 20px 0;

  color: #333; // Adjust the text color as needed
  text-align: center;
  font-size: 16px; // Adjust the font size as needed
  bottom: 0;
  left: 0;
`;
const FooterLink = styled.a`
  color: #333; // Link color
  text-decoration: none; // Removes underline from links

  &:hover {
    text-decoration: underline; // Adds underline on hover for better user interaction
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      © 2024{" "}
      <FooterLink
        href="http://www.oshriwd.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Oshriwd
      </FooterLink>
      . All rights reserved.
    </FooterContainer>
  );
};

export default Footer;
